/*
 * @Author: Summer.Chen
 * @Date:   2022-11-03 14:37:38
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:11:38
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from '@/i18n/resources';
import Backend from 'i18next-xhr-backend';
import { initLangage } from '@/utils';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    resources,
    lng: initLangage() || 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false, // this will do the magic
    },
    debug: false,
  });
export default i18n;
