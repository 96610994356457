/*
 * @Description: 归档维护页面相关接口
 * @Author: Wayne Hu
 * @Date: 2024/12/06 13:56:03
 */

import http from '@/utils/http';

/**
 * @Author: Wayne Hu
 * @description: 获取部件分页列表
 * @param {any} params
 */
export const getComponentPage = (params: any): Promise<Object> => {
  return http.get({ url: '/ams/archiveMaintenance/getComponentPage', params });
};

/**
 * @Author: Wayne Hu
 * @description: 新增部件
 * @param {any} data
 */
export const addComponents = (data: any): Promise<Object> => {
  return http.post({ url: '/ams/archiveMaintenance/addComponent', data });
};

/**
 * @Author: Wayne Hu
 * @description: 删除部件
 * @param {any} params
 */
export const deleteComponent = (params: any): Promise<Object> => {
  return http.delete({ url: '/ams/archiveMaintenance/deleteComponent', params });
};

/**
 * @Author: Wayne Hu
 * @description: 编辑部件
 * @param {any} data
 */
export const editComponent = (data: any): Promise<Object> => {
  return http.put({ url: '/ams/archiveMaintenance/EditComponent', data });
};

/**
 * @Author: Wayne Hu
 * @description: 获取模块分页信息
 * @param {any} params
 */
export const getModulePageList = (params: any): Promise<Object> => {
  return http.get({ url: '/ams/archiveMaintenance/getModulePage', params });
};

/**
 * @Author: Wayne Hu
 * @description: 新增模块
 * @param {any} data
 */
export const addModule = (data: any): Promise<Object> => {
  return http.post({ url: '/ams/archiveMaintenance/addModule', data });
};

/**
 * @Author: Wayne Hu
 * @description: 删除模块
 * @param {any} params
 */
export const deleteModule = (params: any): Promise<Object> => {
  return http.delete({ url: '/ams/archiveMaintenance/deleteModule', params });
};

/**
 * @Author: Wayne Hu
 * @description: 编辑模块
 * @param {any} data
 */
export const editModule = (data: any): Promise<Object> => {
  return http.put({ url: '/ams/archiveMaintenance/editModule', data });
};

/**
 * @Author: Wayne Hu
 * @description: 获取问题内容分页列表
 * @param {any} params
 */
export const getContentPage = (params: any): Promise<Object> => {
  return http.get({ url: '/ams/archiveMaintenance/getContentPage', params });
};

/**
 * @Author: Wayne Hu
 * @description: 新增问题内容
 * @param {any} data
 */
export const addContent = (data: any): Promise<Object> => {
  return http.post({ url: '/ams/archiveMaintenance/addContent', data });
};

/**
 * @Author: Wayne Hu
 * @description: 删除问题内容
 * @param {any} params
 */
export const deleteContent = (params: any): Promise<Object> => {
  return http.delete({ url: '/ams/archiveMaintenance/deleteContent', params });
};

/**
 * @Author: Wayne Hu
 * @description: 更新问题内容
 * @param {any} data
 */
export const editContent = (data: any): Promise<Object> => {
  return http.put({ url: '/ams/archiveMaintenance/editContent', data });
};

/** 刷新数据 */
export const refreshData = (data: any): Promise<Object> => {
  return http.put({ url: '/ams/archiveMaintenance/refreshData', data });
};

/**
 * @Author: YuKi
 * @description: 获取责任归属方列表
 * @param {listSearch} params 接口传参
 */
export const getResponsiblePartyList = (businessLine: string): Promise<Object> =>
  http.get({ url: '/ams/archiveMaintenance/getResponsiblePartyList', params: { businessLine } });
