export const minvList = [
  'Storion-TB250',
  'Storion-T50',
  'Storion-T100',
  'Storion-TB500',
  'Storion-S3',
  'Storion-S5',
  'AEV-5048',
  'SMILE-G3-S5-INV',
  'Smile5-INV',
  'Storion-SMILE-B3',
  'SMILE-B3-PLUS',
  'SMILE-M5-S-INV',
  'RHI-5K-48ES-5G',
  'STORION-SMILE-B5',
  'SMILE-SP9.6-INV',
  'SMILE-SP-9.6-INV',
  'SMILE-SPB9.6-INV',
  'SMILE-S6-HV-INV',
  'SMILE-T10-HV-INV',
  'SMILE-S5',
  'RESERVE-INV-1-P5-L1-INT',
  'SMILE-G3-B5-INV',
  'SMILE-G3-S3.6-INV',
  'SMILE-G3-S8-INV',
  'SMILE-G3-T6-INV',
  'SMILE-G3-T8-INV',
  'SMILE-G3-T10-INV',
  'SMILE-G3-T20-INV',
  'VT-1000',
  'VT1000',
  'Storion-SMILE-T10',
  'Storion-T30',
  'Storion-TB375',
  'Storion-TB150',
  'Storion-T5',
  'Storion-GINLANG-S5',
  'SMILE-SPB7.6-INV',
  'SMILE-SP-7.6-INV',
  'SMILE-SP7.6-INV',
  'SMILE-S6-HV',
  'SMILE-B5',
  'SMILE-S6',
  'SMILE-G3-T15-INV',
  'RESERVE-INV-1-P10-L3-INT',
  'Storion-TB100',
  'SMILE-G3-T4-INV',
  'Storion-T1000',
  'Storion-TB50',
  'Storion-T150',
  'Sinexcel-T50',
  'Storion-TB312',
  'Storion-T30A',
  'Storion-SMILE-M5',
  'SMILE-G3-T12-INV',
  'SMILE-G3-S6-INV',
  'SMILE-G3-B3.6-INV',
  'SMILE-SPB-9.6-INV',
  'SMILE-SPB-7.6-INV',
  'SMILE-T10-HV',
  'SMILE-S5-HV-INV',
  'SMILE-G3-T5-INV',
  'Sinexcel-T30',
  'Storion-TB200',
  'Smile-B3-INV',
];
