/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_basicSettings from './en-US/translation.json';
import zh_basicSettings from './zh-CN/translation.json';
import ja_basicSettings from './ja-JP/translation.json';
import de_basicSettings from './de-DE/translation.json';
import nl_basicSettings from './nl-NL/translation.json';
import it_basicSettings from './it-IT/translation.json';
import sp_basicSettings from './sp-SP/translation.json';
import el_basicSettings from './el-GR/translation.json';
import fr_basicSettings from './fr-FR/translation.json';
export const basicSettings = {
  en_basicSettings,
  zh_basicSettings,
  ja_basicSettings,
  de_basicSettings,
  nl_basicSettings,
  it_basicSettings,
  sp_basicSettings,
  el_basicSettings,
  fr_basicSettings,
};
