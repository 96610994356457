/*
 * @Description: 公共正则表达式校验规则
 * @Author: YuKi
 * @Date: 2023/03/06
 * @LastEditors: Summer.Chen
 * @LastEditTime: 2024-04-09 21:54:00
 */
/* 邮箱校验 */
const emailReg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z0-9_\-\+]{2,8})$/;
export { emailReg };
