/*
 * @Description: 语言切换组件
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/05/11 17:24:12
 * @LastEditTime: 2024/05/10 09:51:04
 */

import React, { forwardRef, useImperativeHandle } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { updateLanguage } from '@/actions/app';
import './index.less';
const { Option } = Select;
const LanguageSelect = forwardRef((props: any, ref) => {
  const dispatch = useDispatch();
  let { i18n } = useTranslation();
  //获取Map中的数据
  let language = useSelector((state: RootStateOrAny) => state.get('app').get('language'));
  useImperativeHandle(ref, () => ({
    onLangSelectChange: onChange,
  }));

  const onChange = (value) => {
    i18n.changeLanguage(value);
    dispatch(updateLanguage(value));
    localStorage.setItem('language', value);
  };

  return (
    <div className="language-select-style" style={{ width: props.languageWidth ? props.languageWidth : '140px' }}>
      <Select
        popupClassName="drop"
        defaultValue={language}
        onChange={(value) => onChange(value)}
        size="middle"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        style={{ width: '100%' }}
        placement="bottomRight"
      >
        <Option value="zh">中文</Option>
        <Option value="en">English</Option>
        <Option value="de">Deutsch</Option>
        <Option value="ja">日本語</Option>
        <Option value="it">Italiano</Option>
        <Option value="nl">Nederlands</Option>
        <Option value="sp">España</Option>
        <Option value="el">Ελληνικά</Option>
        <Option value="fr">Français</Option>
      </Select>
    </div>
  );
});

export default LanguageSelect;
