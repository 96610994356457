/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_clientFeedback from './en-US/translation.json';
import zh_clientFeedback from './zh-CN/translation.json';
import ja_clientFeedback from './ja-JP/translation.json';
import de_clientFeedback from './de-DE/translation.json';
import nl_clientFeedback from './nl-NL/translation.json';
import it_clientFeedback from './it-IT/translation.json';
import sp_clientFeedback from './sp-SP/translation.json';
import el_clientFeedback from './el-GR/translation.json';
import fr_clientFeedback from './fr-FR/translation.json';
export const langcliFeedback = {
  en_clientFeedback,
  zh_clientFeedback,
  ja_clientFeedback,
  de_clientFeedback,
  nl_clientFeedback,
  it_clientFeedback,
  sp_clientFeedback,
  fr_clientFeedback,
  el_clientFeedback,
};
