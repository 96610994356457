/*
 * @Description: 项目根组件
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/05/07 16:16:39
 * @LastEditTime: 2024-03-14 10:44:16
 */
import React, { useEffect } from 'react';
import 'moment/locale/zh-cn';
import renderRoutes from '@/router/renderRoutes';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';

import './App.css';
import './resetAnt.less';
import { BrowserRouter } from 'react-router-dom';
import { getUrlSearchParams } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { updateAlphaCloudSSO } from '@/actions/app';
import { config } from './constants/config';
import { languageLongSwitch } from '@/constants/common';
import { updateLanguage } from '@/actions/app';

require('react-dom');
function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const receiveAlphaCloudMessage = (event: any) => {
    if (config.alphaCloudSSO[location.origin] == event.origin && event.data && event.data.dataType === 3) {
      let languageCode: any = '';
      // languageCode = languageLongSwitch(event.data.languageCode)
      i18n.changeLanguage(event.data.languageCode);
      dispatch(updateLanguage(event.data.languageCode));
      localStorage.setItem('language', event.data.languageCode);
    }
  };
  useEffect(() => {
    // Error log statistic
    window.addEventListener('error', function onError(e) {
      // Ignore ResizeObserver error
      if (e.message === 'ResizeObserver loop limit exceeded') {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
    window.addEventListener('message', receiveAlphaCloudMessage, false);
    return () => {
      window.removeEventListener('message', receiveAlphaCloudMessage);
    };
  }, []);
  let routers = useSelector((state: RootStateOrAny) => state.get('app').get('routers'));
  const AlphaCloudSSO = getUrlSearchParams('AlphaCloudSSO');
  const AlphaCloudToken = getUrlSearchParams('AlphaCloudToken');
  const AlphaCloudSSOState = useSelector((state: RootStateOrAny) => state.get('app').get('AlphaCloudSSO'));
  if (AlphaCloudSSOState !== '1' && AlphaCloudSSO) {
    dispatch(updateAlphaCloudSSO(AlphaCloudSSO));
  }
  localStorage.setItem('AlphaCloudToken', AlphaCloudToken as string);

  return <BrowserRouter>{renderRoutes(routers)}</BrowserRouter>;
}

export default App;
