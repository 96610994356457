/*
 * @Description: 创建客诉相关接口定义
 * @Author: April
 * @LastEditors: YuKi
 * @Date: 2023/02/20 19:57:37
 * @LastEditTime: 2024/05/10
 */

import http from '@/utils/http';
/**
 * @Author: April
 * @LastEditors: April
 * @description: 根据SN获取基本信息
 * @param {any} params
 * @return {*}
 */
export const getSnBasicData = (params?: any): Promise<Object> => {
  return http.get({
    url: '/ams/amsComplaint/getSnBasicData',
    params,
  });
};

/**
 * @Author: April
 * @LastEditors: April
 * @description: 创建新客诉
 * @param {any} data
 * @return {*}
 */
export const createComplaint = (data?: any): Promise<Object> => {
  return http.post({
    url: '/ams/amsComplaint/createComplaint',
    data,
  });
};
/**
 * @description: 获取账号下绑定的sn
 * @return {*}
 * @LastEditors: April
 */
export const getSnList = (): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getSnListByUserID',
  });
};

/**
 * @description: 智能客服跳转的总结
 * @return {*}
 * @LastEditors: YukI
 */
export const apiGetCustSummary = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/ai/getCustSummary',
    data,
  });
};
