/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/09/06 17:12:26
 * @LastEditTime: 2024-03-13 17:56:40
 */

import { app } from './../constants/actionType';
import { setRightOption } from '@/utils/localStoreUtil';
import { countryType } from '@/types';
export const permission = (permissions: []) => {
  return { type: app.UPDATE_PERMISSIONS, permissions };
};
export const updateLanguage = (language: string) => {
  return { type: app.UPDATE_LANGUAGE, language };
};
export const updateToken = (token: string) => {
  return { type: app.UPDATE_TOKEN, token };
};
export const updateJWTToken = (jwt_token: string) => {
  return { type: app.UPDATE_JWT_TOKEN, jwt_token };
};
export const updateCharginPile = (chargingPile: Object) => {
  return { type: app.UPDATE_CHARGING_PILE, chargingPile };
};
export const updateCollapsed = (collapsed: boolean) => {
  localStorage.setItem('collapsed', collapsed.toString());
  return { type: app.UPDATE_COLLASED, collapsed };
};
export const updateRightOPtion = (rightOption: any) => {
  setRightOption(rightOption);
  return { type: app.UPDATE_RIGHT_OPTION, rightOption };
};
export const updateRouters = (routers: any) => {
  return {
    type: app.UPDATE_ROUTERS,
    routers,
  };
};
/* 更新我的消息数量 */
export const updateMessages = (messages: any) => {
  return { type: app.UPDATE_MESSAGES, messages };
};
/* 更新我的消息数量 */
export const updateWindowWidth = (WindowWidth: Number) => {
  return { type: app.UPDATE_WINDOWN_WIDTH, WindowWidth };
};
/* AlphaCloud6.0 SSO */
export const updateAlphaCloudSSO = (AlphaCloudSSO: string) => {
  return { type: app.UPDATE_ALPHAClOUD_SSO, AlphaCloudSSO };
};
/* AlphaCloud6.0 SSO */
export const updateCountryList = (countryList: countryType[]) => {
  localStorage.setItem('countryList', JSON.stringify(countryList));
  return { type: app.UPDATE_COUNTRY_LIST, countryList };
};
/* 数据权限  国家isSetting*/
export const updateIsSetting = (iSetting: boolean) => {
  return { type: app.UPDATE_IS_SETTING, iSetting };
};
