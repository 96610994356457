/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_common from './en-US/translation.json';
import zh_common from './zh-CN/translation.json';
import ja_common from './ja-JP/translation.json';
import de_common from './de-DE/translation.json';
import nl_common from './nl-NL/translation.json';
import it_common from './it-IT/translation.json';
import sp_common from './sp-SP/translation.json';
import el_common from './el-GR/translation.json';
import fr_common from './fr-FR/translation.json';
export const langCommon = {
  en_common,
  zh_common,
  ja_common,
  de_common,
  nl_common,
  it_common,
  sp_common,
  fr_common,
  el_common,
};
