import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, message, Progress } from 'antd';
import { t } from 'i18next';
import http from '@/utils/http';
import './index.less';

const FileUploadNew: React.FC<any> = forwardRef((props, ref) => {
  const { uploadParams } = props;
  const [clickUploadLoading, setClickUploadLoading] = useState(false);
  const [uploadShow, setUploadShow] =
    useState(false); /* 用于控制点击上传的按钮，如果是编辑光用fileList的长度无法判断，所以需要加上此判断 */
  const [fileList, setFileList] = useState<any>([]);

  /* 如果父组件的功能是编辑，需要把父组件的文件数组传过来 */
  useEffect(() => {
    if (uploadParams.fileList && uploadParams.fileList.length > 0) {
      setFileList([...uploadParams.fileList]);
    }
  }, [uploadParams]);

  const fileChange = (event) => {
    // 如果上传附件的个数超过指定数量提示数量超限
    if (uploadParams.limit) {
      if (fileList.length && fileList.length > 0) {
        if (uploadParams.limit < event.target.files.length + fileList.length) {
          message.error(
            t('customercomplaint.FileNumber', {
              number: uploadParams.limit,
            }),
          );
          let obj: any = document.getElementById('file-upload-components');
          obj.value = '';
          return;
        }
      } else if (uploadParams.limit < event.target.files.length) {
        message.error(
          t('customercomplaint.FileNumber', {
            number: uploadParams.limit,
          }),
        );
        let obj: any = document.getElementById('file-upload-components');
        obj.value = '';
        return;
      }
    }
    let errExeFile = '';
    let errFileType = '';
    let errFileSize = '';
    let fileListTemp: any = fileList;
    for (let index = 0; index < event.target.files.length; index++) {
      let pString = event.target.files[index].name; // 文件的全名
      let arr = pString.split('#');
      let lastString = arr[arr.length - 1];
      let arr2 = lastString.split('.'); // 根据'.'将文件全名进行拆分成一个数组
      let fileType = arr2[arr2.length - 1]; // 获取文件的扩展名，最后一个'.'后的内容
      /* 不允许上传可执行文件 */
      if (fileType === 'exe') {
        errExeFile += event.target.files[index].name + '\n';
        continue;
      }
      if (uploadParams.acceptType) {
        if (!uploadParams.acceptType.includes(fileType)) {
          errFileType += event.target.files[index].name + '\n';
          continue;
        }
      }
      if (uploadParams.size) {
        if (event.target.files[index].size / (1024 * 1024) > uploadParams.size) {
          errFileSize += event.target.files[index].name + '\n';
          continue;
        }
      }
      setUploadShow(true);
      fileListTemp.push({
        File: event.target.files[index],
        disabled: false,
        event: null,
        rate: 0,
      });
    }
    setFileList([...fileListTemp]);
    let src = '';
    event.target.value = '';
    if (errExeFile) {
      src += '\n' + t('customercomplaint.exeFile') + ': ' + '\n' + errExeFile;
    }
    if (errFileType) {
      src += '\n' + t('customercomplaint.fileTypeNotSup') + +': ' + '\n' + errFileType;
    }
    if (errFileSize) {
      src +=
        '\n' +
        t('customercomplaint.limitSize', {
          maxSize: uploadParams.size,
        }) +
        ': ' +
        '\n' +
        errFileSize;
    }
    if (src) {
      message.error({
        content: src,
        className: 'price-error-style',
      });
    }
    let obj: any = document.getElementById('file-upload-components');
    obj.value = '';
  };

  /**
   * @Author: YuKi
   * @description: 删除文件
   * @param {*} index
   * @return {*}
   */
  const removeFile = (index) => {
    let fileListTemp = fileList;
    fileListTemp.splice(index, 1);
    setFileList([...fileListTemp]);
    let obj: any = document.getElementById('file-upload-components');
    obj.value = '';
  };

  const uploadFile = () => {
    setClickUploadLoading(true);
    let flag = false;
    let fileListTemp = fileList;
    for (let index = 0; index < fileListTemp.length; index++) {
      const element = fileListTemp[index];
      if (!element.disabled) {
        flag = true;
        let formData = new FormData();
        formData.append('blobContainerName', 'FILES');
        formData.append('fileList', element.File);
        http.formSubmit(uploadParams.action, formData).then((rep) => {
          if (rep && rep.code == 200 && rep.data) {
            element.fileSaveName = rep.data[0].fileSaveName;
            element.blobContainerName = rep.data[0].blobContainerName;
            element.fileName = rep.data[0].fileName;
            element.suffix = rep.data[0].suffix;
            element.disabled = true;
            element.rate = 100;
            setFileList([...fileListTemp]);
          }
        });
      }
    }
    if (!flag) {
      message.error(t('customercomplaint.noFilesUploaded'));
    }
    setClickUploadLoading(false);
    let obj: any = document.getElementById('file-upload-components');
    obj.value = '';
  };

  //子组件暴露方法
  useImperativeHandle(ref, () => ({
    fileList,
    resetFieldsFrom,
    fatherRemoveFile,
  }));

  /**
   * @Author: YuKi
   * @description: 父组件删除，需要删除子组件里面的数组，并且需要用名字查找，序号查找顺序混乱有问题
   * @param {*} name
   * @return {*}
   */
  const fatherRemoveFile = (name) => {
    let fileListTemp = fileList;
    for (let index = 0; index < fileListTemp.length; index++) {
      const element = fileListTemp[index];
      if (element.fileSaveName === name) {
        fileListTemp.splice(index, 1);
        setFileList([...fileListTemp]);
        let obj: any = document.getElementById('file-upload-components');
        obj.value = '';
      }
    }
  };

  /**
   * @Author: YuKi
   * @description: 父组件提交的时候，对表单进行校验，校验成功把值返回给父组件
   * @return {*}
   */
  const resetFieldsFrom = () => {
    let obj: any = document.getElementById('file-upload-components');
    obj.value = '';
  };

  return (
    <>
      <div className="file-upload">
        <div className="file-button">
          <div className="select-file" style={{ marginRight: '20px' }}>
            <input
              width="200px"
              type="file"
              multiple
              onChange={(event) => {
                fileChange(event);
              }}
              id="file-upload-components"
            />
            <Button shape="round" type="primary">
              {t('customercomplaint.chooseFile')}
            </Button>
          </div>
          {fileList.length > 0 && uploadShow ? (
            <Button shape="round" loading={clickUploadLoading} type="primary" onClick={uploadFile}>
              {t('customercomplaint.clickUpload')}
            </Button>
          ) : null}
        </div>
        {fileList.length > 0 ? (
          <ul>
            {fileList.map((item, index) => {
              return (
                <div key={index}>
                  {!item.hidden ? (
                    <li key={index}>
                      <div style={{ width: '85%' }}>
                        <span>
                          {item.File.name}
                          {'[' + (item.File.size / 1024).toFixed(2) + 'KB]'}
                        </span>
                        <Progress
                          strokeColor="#E8AA0D"
                          strokeWidth={22}
                          percent={item.rate}
                          format={(percent) =>
                            percent === 100 ? (
                              <span style={{ position: 'absolute', right: '50px', bottom: '5px', color: '#FFFFFF' }}>
                                100%
                              </span>
                            ) : (
                              <span style={{ position: 'absolute', right: '50px', bottom: '5px', color: '#FFFFFF' }}>
                                {`${percent}`}%
                              </span>
                            )
                          }
                        ></Progress>
                      </div>
                      <div>
                        <Button
                          type="text"
                          className="delete-close"
                          onClick={() => {
                            removeFile(index);
                          }}
                        >
                          {t('common.deleteBtn')}
                        </Button>
                      </div>
                    </li>
                  ) : null}
                </div>
              );
            })}
          </ul>
        ) : null}
      </div>
    </>
  );
});

export default FileUploadNew;
