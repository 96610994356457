/*
 * @Description: 产品规则维护
 * @Author: XuAnjie
 * @Date: 2023-10-11 18:02:34
 * @LastEditors: Summer.Chen
 * @LastEditTime: 2024-03-15 11:33:41
 */
import http from '@/utils/http';

export default {
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 分页获取产品
   * @param {any} params
   * @return {*}
   */
  getProductPage(params: any): Promise<Object> {
    return http.get({
      url: '/ams/productRules/getProductPage',
      params,
    });
  },

  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 分页获取产品规则
   * @param {any} params
   * @return {*}
   */
  getProductRolePage(params: any): Promise<Object> {
    return http.get({
      url: '/ams/productRules/getProductRolePage',
      params,
    });
  },

  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 新增或编辑产品规则
   * @param {any} params
   * @return {*}
   */
  editProductRole(data: any): Promise<Object> {
    return http.post({
      url: '/ams/productRules/editProductRole',
      data,
    });
  },

  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 编辑或新增产品
   * @param {any} params
   * @return {*}
   */
  editProduct(data: any): Promise<Object> {
    return http.post({
      url: '/ams/productRules/editProduct',
      data,
    });
  },

  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 删除产品
   * @param {any} params
   * @return {*}
   */
  delProduct(key: string): Promise<Object> {
    return http.delete({
      url: '/ams/productRules/delProduct',
      params: { key },
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 删除产品规则
   * @param {any} params
   * @return {*}
   */
  delProductRule(key: string): Promise<Object> {
    return http.delete({
      url: '/ams/productRules/delProductRule',
      params: { key },
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 获取逆变器装机量
   * @param {any} params
   * @return {*}
   */
  getMinvInstalledCapacity(data): Promise<Object> {
    return http.post({
      url: '/ams/systemAnalysisReport/getMinvInstalledCapacity',
      data,
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 获取电池装机量
   * @param {any} params
   * @return {*}
   */
  getMbatInstalledCapacity(data): Promise<Object> {
    return http.post({
      url: '/ams/systemAnalysisReport/getMbatInstalledCapacity',
      data,
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 导出逆变器装机量
   * @param {any} params
   * @return {*}
   */
  exportMinvInstalledCapacity(data): Promise<Object> {
    return http.exportExelTemp({
      url: '/ams/systemAnalysisReport/exportMinvInstalledCapacity',
      data,
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 导出电池装机量
   * @param {any} params
   * @return {*}
   */
  exportMbatInstalledCapacity(data): Promise<Object> {
    return http.exportExelTemp({
      url: '/ams/systemAnalysisReport/exportMbatInstalledCapacity',
      data,
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 获取逆变器装机量列表
   * @param {any} params
   * @return {*}
   */
  getMinvInstalledNum(data): Promise<Object> {
    return http.post({
      url: '/ams/systemAnalysisReport/getMinvInstalledNum',
      data,
    });
  },
  /**
   * @Author: XuAnjie
   * @LastEditors: XuAnjie
   * @description: 获取电池装机量列表
   * @param {any} params
   * @return {*}
   */
  getMbatInstalledNum(data): Promise<Object> {
    return http.post({
      url: '/ams/systemAnalysisReport/getMbatInstalledNum',
      data,
    });
  },
};
