/*
 * @Author: Summer.Chen
 * @Date:   2022-11-30 09:39:59
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2023-12-13 16:37:13
 */
const config = {
  imgIP: 'http://192.168.8.64:8053/cloud-rbac/login_page/queryPic/',
  languageList: [
    {
      label: '中文',
      value: 'zh-CN',
      simple: 'zh',
    },
    {
      label: 'English',
      value: 'en-US',
      simple: 'en',
    },
    {
      label: 'Deutsch',
      value: 'de-DE',
      simple: 'de',
    },
    {
      label: '日本語',
      value: 'ja-JP',
      simple: 'ja',
    },
    {
      label: 'Italiano',
      value: 'it-IT',
      simple: 'it',
    },
    {
      label: 'Nederlands',
      value: 'nl-NL',
      simple: 'nl',
    },
    {
      label: 'España',
      value: 'sp-SP',
      simple: 'sp',
    },
    {
      label: 'Ελληνικά',
      value: 'el-GR',
      simple: 'el',
    },
    {
      label: 'Français',
      value: 'fr-FR',
      simple: 'fr',
    },
  ],
  abstractTimeZoneAPIKey: '9236d662ce654d859056bdecae2e0d43', // 调取时区的接口key
  googleMapProKey: 'AIzaSyCowkCMWl18cwLuZ5rsNF0GZU-7eJQXCDw', //谷歌地图生产key
  googleMapDevKey: 'AIzaSyBPMDpoB5OQJ0vCXir5O304WqGizkieuRw', //谷歌地图开发key
  baiduMapKey: '1cUeMmeZtmBlSBzxwKd5nmodlD8gInVk', //百度地图key
  downUrl: 'https://alphatest.blob.core.windows.net/' /*  */,
  oldVersionUrl: 'http://192.168.8.64:10011/login?isNewVersion=0',
  individualCustomer: 'http://192.168.8.64:8004/customerComplaintList/attachmentDetails/', // 单独客诉系统
  tinyKey: '0990dde4fa3e0f907ddaba1e7cf78810421a434e14f1bcc56d46fcc009e711f2',
  alphaCloudSSO: {
    'http://localhost:8101': 'http://localhost:8100',
    'http://192.168.8.64:10033': 'http://192.168.8.64:10031',
    'https://support.alphaess.com': 'https://cloud.alphaess.com',
    'http://huidu.alphaess.com:8092': 'http://huidu.alphaess.com:8086',
    'http://192.168.8.64:8079': 'http://192.168.8.64:8078',
    'http://192.168.8.64:8125': 'http://192.168.8.64:8099',
    'http://dev.alphaess.com:8079': 'http://dev.alphaess.com:8078',
  },
  linkToCreate: '/createComplaint/index', // 外部链接跳转至售后系统创建客诉页面
  alphaCloud: 'https://cloud.alphaess.com/energy/list', // alphaCLoud6.0 储能系统列表
  privacyPolicy: 'https://cloud.alphaess.com/complaintAgreeDescription',
};
export { config };
