/*
 * @Description: 文本框文字限制的个数
 * @Author: Summer.Chen
 * @Date: 2024-04-08 19:13:03
 * @LastEditTime: 2024-04-09 21:43:35
 * @LastEditors: Summer.Chen
 */

export const wordLimit = {
  inverterModelInput: 32, //逆变器型号
  nameInput: 64,
};
