/*
 * @Description:
 * @Author: YuKi
 * @Date: 2023/03/02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-09-04 14:16:57
 */
import http, { chengduHttp } from '@/utils/http';
import axios from 'axios';
import * as Types from './type';
import {
  ILoginSubmit,
  getInstallTime,
  stateOrStateList,
  checkSysSnList,
  checkUsernameList,
  registerList,
  sendRegisterParams,
  forgetPassParams,
  confirmForgetPassParams,
  resetPasswordParams,
  forgetUserNameParams,
} from '@/models/login';
import { config } from '@/constants/config';

/**
 * @description: 获取用户菜单
 * @param {any} params
 * @return {*}
 * @LastEditors: Wayne.Hu
 */
export const getUserMenu = (params?: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/menu/route?systemId=KHM3BrSHjeZH',
    params,
  });
};
/**
 * @Author: YuKi
 * @description: 登录接口
 * @param {ILoginSubmit} data
 * @return {*}
 */
export const loginSubmit = (data: ILoginSubmit): Promise<string> => http.post({ url: '/stable/user/login', data });

/**
 * @Author:   Summer.Chen
 * @DateTime: 2023-03-28 13:16:26
 * @Description: 获取用户信息
 */
export const getUserInfo = (): Promise<Object> => http.get({ url: '/stable/account/getUserInfo' });

/**
 * @Author: YuKi
 * @description: 首页公告
 */
export const getHomeMsg = (): Promise<Object> => http.get({ url: '/msg/user/getHomeMsg' });

/**
 * @Author: YuKi
 * @description: 获取时区列表
 * @return {*}
 */
export const getTimeZones = (): Promise<Object> => http.get({ url: '/stable/user/getTimeZones' });

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取用户信息接口
 * @param {any} params
 */
export const getUserInfoAPI = (): Promise<Object> => {
  return http.get({
    url: '/stable/account/getUserBasicInfo',
  });
};

/*
 * @Author: YuKi
 * @description: 注册时根据sn获取安装时间
 * @param {getInstallTime} params 接口传参
 * @return {*}
 */
export const getInstallTimeBySysSn = (params: getInstallTime): Promise<Object> =>
  http.get({ url: '/stable/user/getInstallTimeBySysSn', params });

/**
 * @Author: YuKi
 * @description: 获取州-市列表
 * @param {stateOrStateList} params 接口传参
 * @return {*}
 */
export const getStateOrState = (params: stateOrStateList): Promise<Object> =>
  http.get({ url: '/stable/user/getStateOrState', params });

/**
 * @Author: YuKi
 * @description: 注册时，检查sn
 * @param {checkSysSnList} params 接口传参
 * @return {*}
 */
export const checkSysSn = (params: checkSysSnList): Promise<Object> =>
  http.get({ url: '/stable/user/checkSysSn', params });

/**
 * @Author: YuKi
 * @description: 检查用户名是否已存在
 * @param {checkUsernameList} params 接口传参
 */
export const checkUsername = (params: checkUsernameList): Promise<Object> =>
  http.get({ url: '/stable/user/checkUsername', params });

/**
 * @Author: YuKi
 * @description: 用户注册
 * @param {registerList} data 接口传参
 */
export const register = (data: registerList): Promise<Object> => http.post({ url: '/stable/user/register', data });

/**
 * @Author: Wayne Hu
 * @description: VPP用户注册
 * @param {any} data
 */
export const VppRegistr = (data: any): Promise<Object> => {
  return http.post({
    url: '/web/vpp/vppUserRegister',
    data,
  });
};

/* Vpp用户关联系统 */
export const VppAssociateSystem = (data: any): Promise<Object> => {
  return http.post({
    url: '/web/vpp/vppAssociate',
    data,
  });
};

/**
 * @Author: YuKi
 * @description: 注册发送激活请求
 * @param {sendRegisterParams} data 接口传参
 * @return {*}
 */
export const sendRegisterActivation = (data: sendRegisterParams): Promise<Object> =>
  http.post({ url: '/stable/user/sendRegisterActivation', data });

/**
 * @Author: YuKi
 * @description: 注册发送激活请求(成都)
 * @param {sendRegisterParams} data 接口传参
 * @return {*}
 */
export const sendRegisterActivationChengdu = (data: sendRegisterParams): Promise<Object> =>
  http.post({ url: '/chengdu/base/user/sendRegisterActivation', data });

/**
 * @Author: YuKi
 * @description: 忘记密码，发送邮件
 * @param {forgetPassParams} data 接口传参
 * @return {*}
 */
export const forgetPassword = (data: forgetPassParams): Promise<Object> =>
  http.post({ url: '/stable/user/forgetPassword', data });

/**
 * @Author: YuKi
 * @description: 忘记密码，校验验证码
 * @param {confirmForgetPassParams} data 接口传参
 * @return {*}
 */
export const confirmCodeForForgetPassword = (data: confirmForgetPassParams): Promise<Object> =>
  http.post({ url: '/stable/user/confirmCodeForForgetPassword', data });

/**
 * @Author: YuKi
 * @description: 忘记密码，重置密码
 * @param {resetPasswordParams} data 接口传参
 */
export const resetPassword = (data: resetPasswordParams): Promise<Object> =>
  http.post({ url: '/stable/user/resetPassword', data });

/**
 * @Author: YuKi
 * @description: 忘记密码，忘记用户名
 * @param {forgetUserNameParams} data 接口传参
 */
export const forgetUserName = (data: forgetUserNameParams): Promise<Object> =>
  http.post({ url: '/stable/user/forgetUserName', data });

/**
 * @description: 注册分享用户连接是否可靠
 * @param {any} params
 * @LastEditors: April
 */
export const checkShareLink = (data: any): Promise<Object> => http.post({ url: '/stable/user/checkShareLink', data });

/**
 * @description: 分享注册
 * @param {any} params
 * @LastEditors: April
 */
export const shareRegister = (data: any): Promise<Object> => http.post({ url: '/stable/user/shareRegister', data });

/**
 * @Author: YuKi
 * @description: 用户退出登入
 */
export const logout = (): Promise<Object> => http.post({ url: '/stable/account/logout' });

/**
 * @description: 用户协议
 * @param {any} params
 * @LastEditors: Wayne.Hu
 */
export const getUserAgreement = (): Promise<Object> => {
  return http.get({
    url: '/stable/user/getUserAgreement',
  });
};
/**
 * @Author: Wayne Hu
 * @description: 获取第三方时区接口
 * @param {string} address
 */
export const getAbstractTimeZoneByAddress = (address: string): Promise<Object> => {
  return axios.get(
    `https://timezone.abstractapi.com/v1/current_time/?api_key=${config.abstractTimeZoneAPIKey}&location=${address}`,
  );
};

/**
 * @Author: April
 * @description: 获取验证码
 * @param {Types} data
 * @return {*}
 */
export const getCode = (data: Types.IGetCodeParams) => {
  return chengduHttp.post({ url: '/oauth2/v1/send/mfa-code', data });
};
/**
 * @Author: April
 * @description: 授权-MFA方式（下一步）
 * @param {Types} data
 * @return {*}
 */
export const authorize = (data: Types.IAuthorizeParams) => {
  return chengduHttp.post({ url: '/oauth2/v1/authorize', data });
};
