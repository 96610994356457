/*
 * @Description: 权限管理
 * @Author: Summer.Chen
 * @Date: 2024-04-07 16:16:07
 * @LastEditTime: 2024/06/21 15:35:25
 */

import http from '@/utils/http';

// 获取组织部门菜单列表
export const queryOrgTree = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizePerms/getOrganizeTree',
    params,
  });
};
//获取组织部门菜单列表
export const queryOrganizeMenu = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizePerms/getOrganizeMenu',
    params,
  });
};
//获取管理员下拉列表
export const queryAdminList = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizePerms/getUserList',
    params,
  });
};
//新增组织
export const addOrg = (data: any): Promise<Object> => {
  return http.post({
    url: '/usercenter/organizePerms',
    data,
  });
};
//编辑组织
export const editOrg = (data: any): Promise<Object> => {
  return http.put({
    url: '/usercenter/organizePerms',
    data,
  });
};
//编辑时获取组织部门详情
export const queryOrgDetail = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizePerms',
    params,
  });
};
// 新增组员
export const addMember = (data: any): Promise<Object> => {
  return http.post({
    url: '/usercenter/organizePerms/addMember',
    data,
  });
};
// 编辑组员
export const editMember = (data: any): Promise<Object> => {
  return http.put({
    url: '/usercenter/organizePerms/editMember',
    data,
  });
};
//获取组员分页列表
export const queryListMemberList = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizePerms/getMemberPage',
    params,
  });
};
//删除组织
export const deleteOrg = (params: any): Promise<Object> => {
  return http.delete({
    url: '/usercenter/organizePerms',
    params,
  });
};
//删除成员
export const deleteTeamMember = (params: any): Promise<Object> => {
  return http.delete({
    url: '/usercenter/organizePerms/removeMember',
    params,
  });
};

// 获取组员菜单权限
// deptId 部门id  userId 用户id
export const queryOrgMemberMenuRightTree = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizePerms/getMemberMenuTree',
    params,
  });
};

// 添加-移除 组员菜单权限
// {
//   "deptId": "", 用户所在的部门id
//   "isAdd": true,类型：true-添加，false-移除
//   "menuId": "",	菜单id
//   "userId": ""选择的用户id
// }
export const editOrgUserMenu = (data: any): Promise<Object> => {
  return http.put({
    url: '/usercenter/organizePerms/editOrgUserMenu',
    data,
  });
};
// 数据权限管理
/**
 * @description: 查找用户组
 * @Author: Summer.Chen
 * @Date: 2024-04-11 15:57:21
 * @param {groupName 用户组名称   selectArea  0:全部 1:当前账号所负责的 }
 * @LastEditors: Summer.Chen
 */
export const queryUserGroupData = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizeData/getUserGroupData',
    params,
  });
};
//根据部门id查询国家-地区权限信息
export const queryCountryAuthByDeptId = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizeData/getCountryAuthByDeptId',
    params,
  });
};
//编辑国家-地区权限
export const editCountryPerm = (data: any): Promise<Object> => {
  return http.put({
    url: '/usercenter/organizeData/editCountryPerm',
    data,
  });
};
//新增SN编码规则
export const addSnCodeAuth = (data: any): Promise<Object> => {
  return http.post({
    url: '/usercenter/organizeData/InsertSnCodeAuth',
    data,
  });
};
//新增逆变器型号权限
export const addInverterAuth = (data: any): Promise<Object> => {
  return http.post({
    url: '/usercenter/organizeData/InsertInverterAuth',
    data,
  });
};
//根据部门id查询SN编码规则
export const querySnCodeAuthByDeptId = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizeData/getSnCodeAuthByDeptId',
    params,
  });
};
//获取关联分销商权限信息
export const queryRelateReseller = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizeData/getRelateReseller',
    params,
  });
};
// SN编码规则删除+逆变器型号删除
export const delSNCodeRulesOrInverterModel = (params: any): Promise<Object> => {
  return http.delete({
    url: '/usercenter/organizeData/deleteSnCodeAndInverterAuth',
    params,
  });
};

//根据部门id查询逆变器型号
export const queryInverterAuthByDeptId = (params: any): Promise<Object> => {
  return http.get({
    url: '/usercenter/organizeData/getInverterAuthByDeptId',
    params,
  });
};
//编辑关联分销商权限
export const editRelateReseller = (data: any): Promise<Object> => {
  return http.put({
    url: '/usercenter/organizeData/editRelateReseller',
    data,
  });
};
//根据用户组类型查询用户组List
export const getGroupListByType = (groupType: number): Promise<Object> => {
  return http.get({
    url: '/usercenter/userGroup/getGroupListByType',
    params: { groupType },
  });
};

//绑定用户组
export const bindRole = (data): Promise<Object> => {
  return http.post({
    url: '/usercenter/userGroup/bindGroup',
    data,
  });
};
/**
 * @Author: Wayne Hu
 * @description: 删除绑定用户组
 * @param {any} data
 */
export const deleteBindUserGroup = (data: any) => {
  return http.delete({ url: '/usercenter/userGroup/delBindGroup', data })
}

//获取用户已绑定的用户组
export const getUserBindGroup = (userId: string): Promise<Object> => {
  return http.get({
    url: '/usercenter/userGroup/getUserBindGroup',
    params: { userId },
  });
};
