import http from '@/utils/http';

import { listSearch, listUpdate } from '@/models/maintenanceManagement/businessLineMaintenance';

/**
 * @Author: YuKi
 * @description: 分页获取业务线
 * @param {listSearch} params 接口传参
 * @return {*}
 */
export const getBusinessLinePage = (params: listSearch): Promise<Object> =>
  http.get({ url: '/ams/businessLine/getBusinessLinePage', params });

/**
 * @Author: YuKi
 * @description:编辑业务线
 * @param {listUpdate} data  接口传参
 * @return {*}
 */
export const editBusinessLine = (data: listUpdate): Promise<Object> =>
  http.post({ url: '/ams/businessLine/editBusinessLine', data });

/**
 * @Author: YuKi
 * @description:删除业务线
 * @param {string} id  接口传参
 * @return {*}
 */
export const delBusinessLine = (id: string): Promise<string> =>
  http.delete({ url: `/ams/businessLine/delBusinessLine?id=${id}` });
