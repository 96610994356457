/*
 * @Description: 项目名称
 * @Author: April
 * @Date: 2023-02-20 10:10:10
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/11/09
 */
import http from '@/utils/http';
// 获取分页列表
export const getProjectInfoPage = (params: any): Promise<Object> =>
  http.get({ url: '/ams/projectManage/getProjectInfoPage', params });
// 新增
export const insertProject = (data: any): Promise<object> => {
  return http.post({
    url: '/ams/projectManage/insertProject',
    data,
  });
};
// 更新
export const updateProject = (data: any): Promise<object> => {
  return http.put({
    url: '/ams/projectManage/updateProject',
    data,
  });
};
// 删除
export const deleteProject = (params: any): Promise<object> => {
  return http.delete({
    url: '/ams/projectManage/deleteProject',
    params,
  });
};
// 获取逆变器型号列表
export const getMinvsList = (): Promise<string> => http.get({ url: '/stable/informationCenter/getProductModelList' });
// 文件上传
export const fileUpload = (data: any): Promise<Object> => http.post({ url: '/base/fileOperation/fileUpload', data });
// 获取关联用户组列表
export const getGroupListByType = (params): Promise<string> =>
  http.get({ url: '/ams/projectManage/getGroupListByType', params });
// 绑定用户组
export const bindUserGroup = (params: any): Promise<object> => {
  return http.put({
    url: '/ams/projectManage/bindUserGroup',
    params,
  });
};

// 获取分页列表
export const getInvSpecSn = (params: any): Promise<Object> =>
  http.get({ url: '/ams/projectManage/getInvSpecSn', params });
