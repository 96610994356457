/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_communicationDataManage from './en-US/translation.json';
import zh_communicationDataManage from './zh-CN/translation.json';
import ja_communicationDataManage from './ja-JP/translation.json';
import de_communicationDataManage from './de-DE/translation.json';
import nl_communicationDataManage from './nl-NL/translation.json';
import it_communicationDataManage from './it-IT/translation.json';
import sp_communicationDataManage from './sp-SP/translation.json';
import el_communicationDataManage from './el-GR/translation.json';
import fr_communicationDataManage from './fr-FR/translation.json';
export const communicationDataManage = {
  en_communicationDataManage,
  zh_communicationDataManage,
  ja_communicationDataManage,
  de_communicationDataManage,
  nl_communicationDataManage,
  it_communicationDataManage,
  sp_communicationDataManage,
  fr_communicationDataManage,
  el_communicationDataManage,
};
