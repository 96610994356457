/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/09/06 17:12:26
 * @LastEditTime: 2023/09/08 17:15:17
 */

import { user } from './../constants/actionType';
import { userInfo } from '@/models/login/index';

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 更新用户类型
 * @param {string} userType
 * @return {*}
 */
export const updateUserType = (userType: string) => {
  localStorage.setItem('userType', userType);
  return {
    type: user.UPDATE_USER_TYPE,
    userType,
  };
};
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 更新登录者的组织ID
 * @param {string} loginCoId
 * @return {*}
 */
export const updateLoginCoId = (loginCoId: string) => {
  localStorage.setItem('loginCoId', loginCoId);
  return {
    type: user.UPDATE_LOGIN_COID,
    loginCoId,
  };
};
export const updateRoles = (roles: []) => {
  localStorage.setItem('roles', JSON.stringify(roles));
  return { type: user.UPDATE_ROLES, roles };
};

export const updateUserName = (userName: string) => {
  localStorage.setItem('userName', userName);
  return { type: user.UPDATE_USERNAME, userName };
};
export const updateCountry = (country: string) => {
  localStorage.setItem('country', country);
  return { type: user.UPDATE_COUNTRY, country };
};
export const updateUserInfo = (userInfo: userInfo) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  return { type: user.UPDATE_USER_INFO, userInfo };
};

export const updateUserIcdcId = (userIcdcId: string) => {
  localStorage.setItem('userIcdcId', userIcdcId);
  return { type: user.UPDATE_ICDCID, userIcdcId };
};
export const updateSnLoading = (snLoading: any) => {
  localStorage.setItem('snLoading', snLoading);
  return { type: user.UPDATE_SNLOADING, snLoading };
};
