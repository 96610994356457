/*
 * @Description:
 * @Author: April
 * @Date: 2023-10-10 09:10:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025/01/08 09:50:31
 */
import http from '@/utils/http';

/* 获取客户历史客诉列表 */
export const getClientComplaints = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getClientComplaints',
    params,
  });
};

/* 获取非客诉列表 */
export const getNoClientComplaints = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getNoClientComplaints',
    params,
  });
};

/* 导出客诉列表数据 */
export const exportClientComplaints = (params: any): Promise<Object> => {
  return http.exportExelTempGet({
    url: '/ams/sysComplaint/exportClientComplaints',
    params,
  });
};

/* 导出非客户历史客诉列表 */
export const exportNoClientComplaints = (params: any): Promise<Object> => {
  return http.exportExelTempGet({
    url: '/ams/sysComplaint/exportNoClientComplaints',
    params,
  });
};

/* 获取角色列表 */
export const getRoleList = (): Promise<Object> => http.get({ url: '/ams/sysComplaint/getRoleList' });

/* 获取客诉详情信息 */
export const viewComplaintsList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getClientComplaintInfo',
    params,
  });
};

/* 获取非客户的历史客诉详情 */
export const viewComplaint = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/viewNoClientComplaint',
    params,
  });
};

/* 获取客户留言列表 */
export const getClientComplaintComment = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getClientComplaintComment',
    params,
  });
};

/* 获取非客户留言内容 */
export const getNoClientComplaintComment = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getNoClientComplaintComment',
    params,
  });
};

/* 获取客诉进度 */
export const getHandleProcessingRateList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaint/getHandleProcessing',
    params,
  });
};

/* 点击查看详情 */
export const getViewComplaintsList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/viewComplaintsList',
    params,
  });
};

/* 获取上门服务数据 */
export const getOnSiteServiceRecords = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/sysComplaint/getOnSiteServiceRecords',
    params,
  });
};

/* 导出客诉列表数据 */
export const exportOnSiteServiceRecords = (params: any): Promise<Object> => {
  return http.exportExelTempGet({
    url: '/ams/sysComplaint/exportOnSiteServiceRecords',
    params,
  });
};
