/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_aboutAlphaCloud from './en-US/translation.json';
import zh_aboutAlphaCloud from './zh-CN/translation.json';
import ja_aboutAlphaCloud from './ja-JP/translation.json';
import de_aboutAlphaCloud from './de-DE/translation.json';
import nl_aboutAlphaCloud from './nl-NL/translation.json';
import it_aboutAlphaCloud from './it-IT/translation.json';
import sp_aboutAlphaCloud from './sp-SP/translation.json';
import el_aboutAlphaCloud from './el-GR/translation.json';
import fr_aboutAlphaCloud from './fr-FR/translation.json';

export const langAboAlpCloud = {
  en_aboutAlphaCloud,
  zh_aboutAlphaCloud,
  ja_aboutAlphaCloud,
  de_aboutAlphaCloud,
  nl_aboutAlphaCloud,
  it_aboutAlphaCloud,
  sp_aboutAlphaCloud,
  el_aboutAlphaCloud,
  fr_aboutAlphaCloud,
};
