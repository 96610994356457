/*
 * @Description:
 * @Author: XuAnjie
 * @Date: 2023-10-11 18:02:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025/03/17
 */
import http from '@/utils/http';
import { ComplaintListStatus, ifClient, isIndustryAndCommerce } from '@/utils/utils';
/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取客诉列表
 * @param {any} params
 */
export const getCustomerComplaintsList = (data: any): Promise<Object> => {
  let url = '';
  switch (isIndustryAndCommerce()) {
    case ComplaintListStatus.consumerComplaintList:
      url = '/ams/amsComplaint/getVisitorAmsComplaintPage';
      break;
    case ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage:
      url = '/ams/amsComplaint/getCiAmsComplaintPage';
      break;
    default:
      url = '/ams/amsComplaint/getAmsComplaintPage';
      break;
  }
  return http.post({
    url,
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取客诉列表详情
 * @param {any} params
 * @return {*}
 */
export const getCustomerComplaintDeatil = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/amsComplaint/getAmsComplaintDetail',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取客诉列表详情状态日志
 * @param {any} params
 * @return {*}
 */
export const getAmsComplaintProcessList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/amsComplaint/getAmsComplaintProcessList',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 查询客诉详情操作列表
 * @param {any} params
 * @return {*}
 */
export const getAmsComplaintOperateList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/amsComplaintProcess/getAmsComplaintOperateList',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取客诉工单详情
 * @param {any} params
 * @return {*}
 */
export const getAmsWorkOrderDetail = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/amsComplaint/getAmsWorkOrderDetail',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取客诉工单详情步骤条
 * @param {any} params
 * @return {*}
 */
export const getAmsWorkOrderProcessList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/amsComplaint/getAmsWorkOrderProcessList',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 导出客诉列表数据
 * @param {any} data
 * @return {*}
 */
export const exportComplaintList = (data: any, roles): Promise<Object> => {
  let url = '';
  if (ifClient(roles)) {
    switch (isIndustryAndCommerce()) {
      case ComplaintListStatus.consumerComplaintList:
        url = '/ams/amsComplaint/exportVisitorAmsComplaints';
        break;
      case ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage:
        url = '/ams/amsComplaint/exportCiAmsComplaints';
        break;
      default:
        url = '/ams/amsComplaint/exportAmsComplaints';
        break;
    }
  } else {
    switch (isIndustryAndCommerce()) {
      case ComplaintListStatus.consumerComplaintList:
        url = '/ams/complaintListExport/exportConsumerComplaints';
        break;
      case ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage:
        url = '/ams/complaintListExport/exportCiComplaints';
        break;
      default:
        url = '/ams/complaintListExport/exportResidentialComplaints';
        break;
    }
  }
  return http.exportExelTemp({
    url,
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 保存去处理
 * @param {any} data
 * @return {*}
 */
export const saveHandle = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/handle',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 保存转产品
 * @param {any} data
 * @return {*}
 */
export const saveTransferProduct = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/transferProduct',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 保存转质控
 * @param {any} data
 * @return {*}
 */
export const saveTransferQuality = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/transferQuality',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 保存转中式
 * @param {any} data
 * @return {*}
 */
export const saveTransferTest = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/transferTest',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 保存转移
 * @param {any} data
 * @return {*}
 */
export const saveTransferOperate = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/transferOperate',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 保存退回
 * @param {any} data
 * @return {*}
 */
export const saveBackOperate = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/backOperate',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 转CRM
 * @param {any} data
 * @return {*}
 */
export const saveTransferCrmOperate = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/transferCrmOperate',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 转运营
 * @param {any} data
 * @return {*}
 */
export const saveTransferBusinessOperate = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/transferBusinessOperate',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取质控表单
 * @param {any} data
 * @return {*}
 */
export const getQualityForm = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getQualityForm',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取产品表单
 * @param {any} data
 * @return {*}
 */
export const getProductForm = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getProductForm',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取去处理表单
 * @param {any} data
 * @return {*}
 */
export const getHandleForm = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getHandleForm',
    params,
  });
};

/**
 * @description: 获取转CRM表单详情
 * @param {any} params
 * @return {*}
 * @LastEditors: XuAnjie
 */
export const getTransferCrmForm = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getTransferCrmForm',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取运营表单
 * @param {any} data
 * @return {*}
 */
export const getBusinessForm = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getBusinessForm',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取中试表单
 * @param {any} data
 * @return {*}
 */
export const getTestForm = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getTestForm',
    params,
  });
};

/**
 * @Author: YuKi
 * @description:客诉列表->删除
 * @param {any} params  接口传参
 * @return {*}
 */
export const apiwithdrawComplaints = (params: any): Promise<string> =>
  http.delete({ url: '/base/complaint/withdrawComplaints', params });

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取客诉留言数据
 * @param {any} params
 */
export const getComplaintComment = (params: any): Promise<Object> => {
  return http.get({
    url: '/base/complaint/getComplaintComment',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 回复客诉留言
 * @param {any} data
 * @return {*}
 */
export const replyComplaintComment = (data: any): Promise<Object> => {
  return http.post({
    url: '/base/complaint/replyComplaintComment',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 撤回留言
 * @param {any} params
 * @return {*}
 */
export const withdrawComment = (params: any): Promise<Object> => {
  return http.delete({
    url: '/base/complaint/withdrawComment',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 提交客诉留言内容
 * @param {any} data
 * @return {*}
 */
export const submitComplaintComment = (data: any): Promise<Object> => {
  return http.post({
    url: '/base/complaint/submitComplaintComment',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 获取表单信息
 * @param {any} data
 * @return {*}
 */
export const getFormInfo = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getFormInfo',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 归档获取部件列表
 * @param {any} data
 */
export const getComponentList = (params): Promise<Object> => {
  return http.get({
    url: '/ams/archiveMaintenance/getComponentList',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 归档获取模块列表
 * @param {any} data
 */
export const getModuleList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/archiveMaintenance/getModuleList',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 归档获取问题内容列表
 * @param {any} data
 * @return {*}
 */
export const getContentList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/archiveMaintenance/getContentList',
    params,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 归档获取问题内容列表
 * @param {any} data
 * @return {*}
 */
export const saveArchive = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/archive',
    data,
  });
};

/**
 * @Author: XuAnjie
 * @LastEditors: XuAnjie
 * @description: 归档获取问题内容列表
 * @param {any} data
 * @return {*}
 */
export const getArchiveInfo = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintForm/getArchiveInfo',
    params,
  });
};

/* 获取留言内容 */
export const getComment = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintComment/getComment',
    params,
  });
};
/* 提交留言 */
export const submitComment = (data?: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintComment/submitComment',
    data,
  });
};

/* 回复留言 */
export const replyComment = (data?: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintComment/replyComment',
    data,
  });
};
/* 提交客诉评价 */
export const evaluateComplaint = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/amsComplaint/evaluate',
    data,
  });
};

/* 客诉批量归档 */
export const batchArchive = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/batchArchive',
    data,
  });
};

/* 可留言的用户组列表 */
export const getCommentGroupList = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/complaintComment/getCommentGroupList',
    params,
  });
};

/* 获取批量归档列表 */
export const getBatchFlag = (params: any): Promise<Object> => {
  let url = '';
  switch (isIndustryAndCommerce()) {
    case ComplaintListStatus.consumerComplaintList:
      url = '/ams/complaintForm/getVisitorBatchFlag';
      break;
    case ComplaintListStatus.listIndustrialAndCommercialCustomerComplaintsPage:
      url = '/ams/complaintForm/getCiBatchFlag';
      break;
    default:
      url = '/ams/complaintForm/getBatchFlag';
      break;
  }
  return http.get({
    url,
    params,
  });
};

/* 提交重点客诉表单 */
export const submitImportKeyCusCom = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/emphasis/submit',
    data,
  });
};

/* 关联重点客诉 */
export const associateImportKeyCusCom = (ticketNumber: string): Promise<Object> => {
  return http.get({
    url: '/ams/emphasis/associate',
    params: { ticketNumber },
  });
};

/* 获取重点客诉列表 */
export const getEmphasisPage = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/emphasis/page',
    data,
  });
};

/** 获取重点客诉列表详情 */
export const getEmphasisDetail = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/emphasis/detail',
    params,
  });
};

/* 修改8D报告 */
export const updateEightReport = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/updateEightReport',
    data,
  });
};

/* 重点客诉结束 */
export const emphasisFinish = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/complaintForm/emphasisFinish',
    data,
  });
};

/* 新增重点客诉进度 */
export const addEmphasisProgress = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/emphasis/addEmphasisProgress',
    data,
  });
};

/** 获取重点客诉进度 */
export const getEmphasisProgress = (params: any): Promise<Object> => {
  return http.get({
    url: '/ams/emphasis/getEmphasisProgress',
    params,
  });
};

/* 编辑重点客诉 */
export const editEmphasis = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/emphasis/editEmphasis',
    data,
  });
};
