/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_aftersalesManagement from './en-US/translation.json';
import zh_aftersalesManagement from './zh-CN/translation.json';
import ja_aftersalesManagement from './ja-JP/translation.json';
import de_aftersalesManagement from './de-DE/translation.json';
import nl_aftersalesManagement from './nl-NL/translation.json';
import it_aftersalesManagement from './it-IT/translation.json';
import sp_aftersalesManagement from './sp-SP/translation.json';
import el_aftersalesManagement from './el-GR/translation.json';
import fr_aftersalesManagement from './fr-FR/translation.json';

export const aftersalesManagement = {
  en_aftersalesManagement,
  zh_aftersalesManagement,
  ja_aftersalesManagement,
  de_aftersalesManagement,
  nl_aftersalesManagement,
  it_aftersalesManagement,
  sp_aftersalesManagement,
  el_aftersalesManagement,
  fr_aftersalesManagement,
};
