/*
 * @Description: 罗马尼亚安规相关接口
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/05/23 13:59:08
 * @LastEditTime: 2023/05/23 15:29:05
 */
import http from '@/utils/http';

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 分页获取消息配置列表
 * @param {any} params
 * @return {*}
 */
export const getMsgConfigurationPage = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/msgConfiguration/getMsgConfigurationPage',
    data,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取消息配置详细信息
 * @param {any} params
 * @return {*}
 */
export const getMsgConfigurationDetail = (id: string): Promise<Object> => {
  return http.get({
    url: '/ams/msgConfiguration/getMsgConfigurationDetail',
    params: { id },
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 新增或编辑消息配置
 * @param {any} params
 * @return {*}
 */
export const editMsgConfiguration = (data: any): Promise<Object> => {
  return http.post({
    url: '/ams/msgConfiguration/editMsgConfiguration',
    data,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 删除消息配置
 * @param {any} data
 * @return {*}
 */
export const delMsgConfiguration = (id: any): Promise<Object> => {
  return http.delete({
    url: '/ams/msgConfiguration/delMsgConfiguration',
    params: { id },
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取类型源数据
 * @param {any} data
 * @return {*}
 */
export const getMsgType = (): Promise<Object> => {
  return http.get({
    url: '/ams/msgConfiguration/getMsgType',
  });
};
