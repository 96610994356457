const   country = [
     "Albania",
     "Andorra",
     "Argentina",
     "Austria",
     "Azerbaijan",
     "Belarus",
     "Belgium",
     "Bolivia",
     "Bosna",
     "Hercegovina",
     "Brazil",
     "Bulgaria",
     "Cameroon",
     "Costa Rica",
     "Croatia",
     "Cuba",
     "Chile",
     "Colombia",
     "Cyprus",
     "Czech Republic",
     "Denmark",
     "Dominican Republic",
     "Ecuador",
     "El Salvador",
     "Estonia",
     "Faroe",
     "Finland",
     "France",
     "Germany",
     "Greece",
     "Greenland",
     "Guatemala",
     "Honduras",
     "Hungary",
     "Indonesia",
     "Iceland",
     "Italy",
     "Latvia",
     "Lithuania",
     "Moldova",
     "Netherlands",
     "Norway",
     "Nicaragua",
     "Panama",
     "Paraguay",
     "Peru",
     "Poland",
     "Portugal",
     "Romania",
     "Russia",
     "Slovakia",
     "South Africa",
     "Slovenia",
     "Spain",
     "Sweden",
     "Switzerland",
     "Turkey",
     "Ukraine",
     "Uruguay",
     "Venezuela",
     "Viet Nam",
     "Zimbabwe"
]

 
export default country