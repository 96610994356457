/*
 * @Description: action types
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/09/06 17:12:27
 * @LastEditTime: 2024-03-13 17:40:08
 */

/* app action types */
export const app = {
  UPDATE_ROUTERS: 'UPDATE_ROUTERS',
  UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  UPDATE_JWT_TOKEN: 'UPDATE_JWT_TOKEN',
  UPDATE_CHARGING_PILE: 'UPDATE_CHARGING_PILE',
  UPDATE_COLLASED: 'UPDATE_COLLASED',
  UPDATE_RIGHT_OPTION: 'UPDATE_RIGHT_OPTION',
  UPDATE_MESSAGES: 'UPDATE_MESS',
  UPDATE_WINDOWN_WIDTH: 'UPDATE_WINDOWN_WIDTH',
  UPDATE_ALPHAClOUD_SSO: 'UPDATE_ALPHAClOUD_SSO',
  UPDATE_COUNTRY_LIST: 'UPDATE_COUNTRY_LIST',
  UPDATE_IS_SETTING: 'UPDATE_IS_SETTING',
};
/* user action types */
export const user = {
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_COUNTRY: 'UPDATE_COUNTRY',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  UPDATE_ROLES: 'UPDATE_ROLES',
  UPDATE_ICDCID: 'UPDATE_ICDCID',
  UPDATE_SNLOADING: 'UPDATE_SNLOADING',
  UPDATE_LOGIN_COID: 'UPDATE_LOGIN_COID',
  UPDATE_USER_TYPE: 'UPDATE_USER_TYPE',
};
/* energyStorage action types */
export const energyStorage = {
  UPDATE_WEATHER: 'UPDATE_WEATHER',
  UPDATE_CURRENT_ITEM: 'UPDATE_CURRENT_ITEM',
  UPDATE_SN_SEARCH: 'UPDATE_SN_SEARCH',
};
