/*
 * @Description:防抖
 * @Author: Summer.Chen
 * @Date: 2024-03-13 13:12:51
 * @LastEditTime: 2024-03-27 10:43:40
 * @LastEditors: Summer.Chen
 */
import { useRef, useEffect } from 'react';

export function useDebounce(fn: any, delay: number) {
  const timer: any = useRef(null);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (...args: any) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
