/*
 * @Description: 项目名称
 * @Author: April
 * @Date: 2023-02-20 10:10:10
 * @LastEditors: XuAnjie
 * @LastEditTime: 2023-11-24 16:09:12
 */
import http from '@/utils/http';
// 获取分页列表
export const getSyncResultPage = (params: any): Promise<Object> =>
  http.get({ url: '/ams/syncResult/getSyncResultPage', params });

// 获取同步结果
export const getSyncResult = (params: any): Promise<Object> =>
  http.get({ url: '/ams/syncResult/getSyncResult', params });

// 重新同步
export const retrySync = (params: any): Promise<Object> => http.put({ url: '/ams/syncResult/retrySync', params });
