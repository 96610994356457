/*
 * @Description: 角色管理相关接口
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/04/25 09:10:33
 * @LastEditTime: 2023/04/27 16:31:02
 */
import http from '@/utils/http';

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 新增角色
 * @param {any} data
 * @return {*}
 */
export const addRole = (data: any): Promise<Object> => {
  return http.post({
    url: '/web/roleManage/addSysRole',
    data,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 删除指定角色
 * @param {any} params
 * @return {*}
 */
export const deleteRole = (params: any): Promise<Object> => {
  return http.delete({
    url: '/web/roleManage/deleteSysRole',
    params,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 修改角色信息
 * @param {any} data
 * @return {*}
 */
export const editRoleInfo = (data: any): Promise<Object> => {
  return http.put({
    url: '/web/roleManage/editSysRole',
    data,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取角色分页列表
 * @param {any} params
 * @return {*}
 */
export const getRoleList = (params: any): Promise<Object> => {
  return http.get({
    url: '/web/roleManage/getSysRoleList',
    params,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 设置角色菜单权限
 * @param {any} data
 * @return {*}
 */
export const setRoleMenuPermission = (data: any): Promise<Object> => {
  return http.put({
    url: '/web/roleManage/setRoleMenuPermission',
    data,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 配置角色首页前，获取当前角色拥有的树状菜单
 * @param {any} params
 * @return {*}
 */
export const getRoleMenuTreeData = (params: any): Promise<Object> => {
  return http.get({
    url: '/web/roleManage/getRoleMenuTreeData',
    params,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 给角色配置菜单前，先获取所有菜单的树形结构
 * @param {any} params
 * @return {*}
 */
export const getRoleMenuPermission = (params: any): Promise<Object> => {
  return http.get({
    url: '/web/roleManage/getRoleMenuPermission',
    params,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 保存角色首页菜单
 * @param {any} data
 * @return {*}
 */
export const saveRoleHome = (data: any): Promise<Object> => {
  return http.put({
    url: '/web/roleManage/setRoleHomePage',
    data,
  });
};
