/*
 * @Description:
 * @Author: YuKi
 * @Date: 2023/02/20
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2024/05/08 11:22:06
 */
import CryptoJS from 'crypto-js/crypto-js';
import nation from '../../public/common/js/nation';
import { message } from 'antd';
import { config } from '@/constants/config';
// 下载或导出
export function blobDownloadFile(rep, fileName) {
  let name = '';
  let regex = /"([^"]*)"/g;
  let currentResult;
  let results = [];
  let strValue = '';
  if (fileName) {
    name = fileName;
  } else {
    if (rep.headers['content-disposition']) {
      name = rep.headers['content-disposition'].split(';')[1].split('=')[1];
      name = name.replace(new RegExp('"', 'g'), '');
      name = decodeURIComponent(name);
    }
  }
  if (rep.headers['content-disposition']) {
    strValue = rep.headers['content-disposition'].split(';')[1];
    while ((currentResult = regex.exec(strValue)) !== null) {
      results.push(currentResult[1]);
    }
  }
  let fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result);
      // token 通知父返回通知页面
      if (jsonData.code === 6070) {
        window.parent.postMessage({ dataType: 8 }, config.alphaCloudSSO[location.origin]);
        return;
      }
      if (jsonData.msg) {
        message.error(jsonData.msg);
      }
    } catch (error) {
      let fileDownload = require('js-file-download');
      fileDownload(rep.data, name);
    }
  };
  fileReader.readAsText(rep.data);
}

/**
 * @Author: YuKi
 * @description: 密码加密
 * @return {*}
 */
export function encrypt(password, username) {
  let aeskey = CryptoJS.SHA256(username);
  let iv = CryptoJS.MD5(username);
  let encrypted = CryptoJS.AES.encrypt(password, aeskey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

/**
 * @Author: XuAnjie
 * @description: 密码解密
 * @return {*}
 */
export function decrypt(password, username) {
  let aeskey = CryptoJS.SHA256(username);
  let iv = CryptoJS.MD5(username);
  let encrypted = CryptoJS.AES.decrypt(password, aeskey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedStr = encrypted.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

/* 读取图片文件流 */
export function parsePictureStream(rep) {
  let fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onload = function () {
      try {
        resolve(URL.createObjectURL(rep.data));
      } catch (err) {
        reject('error');
      }
    };
    fileReader.readAsText(rep.data);
  });
}

export function getRequestValue(key) {
  // 获取参数
  let url = window.location.search;
  // 正则筛选地址栏
  let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)');
  // 匹配目标参数
  let result = url.substr(1).match(reg);
  // 返回参数值
  return result ? decodeURIComponent(result[2]) : null;
}

export function languageSwitch(lang) {
  let shortLang = '';
  switch (lang) {
    case 'zh-CN':
      shortLang = 'zh';
      break;
    case 'en-US':
      shortLang = 'en';
      break;
    case 'de-DE':
      shortLang = 'de';
      break;
    case 'ja-JP':
      shortLang = 'ja';
      break;
    case 'it-IT':
      shortLang = 'it';
      break;
    case 'nl-NL':
      shortLang = 'nl';
      break;
    case 'sp-SP':
      shortLang = 'sp';
      break;
    case 'el-GR':
      shortLang = 'el';
      break;
    case 'fr-FR':
      shortLang = 'fr';
      break;
    default:
      shortLang = 'zh';
      break;
  }
  return shortLang;
}

export function languageLongSwitch(lang) {
  let longLang = '';
  switch (lang) {
    case 'zh':
      longLang = 'zh-CN';
      break;
    case 'en':
      longLang = 'en-US';
      break;
    case 'de':
      longLang = 'de-DE';
      break;
    case 'ja':
      longLang = 'ja-JP';
      break;
    case 'it':
      longLang = 'it-IT';
      break;
    case 'nl':
      longLang = 'nl-NL';
      break;
    case 'sp':
      longLang = 'sp-SP';
      break;
    case 'el':
      longLang = 'el-GR';
      break;
    case 'fr':
      longLang = 'fr-FR';
      break;
    default:
      longLang = 'zh-CN';
      break;
  }
  return longLang;
}

/**
 * @Author: YuKi
 * @description: 常规情况下：input改造inputNumber,“.”和“,”处理
 * @param {*} formName 处理的表单名字
 * @param {*} key 处理的表单的key
 * @return {*}
 */
export function inputLimit(formName, key, country) {
  let isCommaCountry = nation.indexOf(country) > -1 ? true : false;
  let valueFormat = '';
  let value = formName.getFieldValue(key);
  if (value == null || value === '' || value === undefined) {
    return;
  }
  if (typeof value == 'number') {
    value = value.toString();
  }
  let hasComma = value.indexOf(',') > -1 ? true : false;
  let hasDot = value.indexOf('.') > -1 ? true : false;
  if (isCommaCountry) {
    /* 如果是类似于德国的国家，可以输入',' 、'.'。但是两个不能同时存在*/
    if ((hasComma && !hasDot) || (hasComma && hasDot && value.indexOf(',') < value.indexOf('.'))) {
      valueFormat = value.replace(/[^-?0-9,]/g, ''); //清除“数字”和“,”以外的字符
      valueFormat = valueFormat.replace(/^\,/g, ''); //验证第一个字符是数字而不是，
    } else if (
      (hasDot && !hasComma) ||
      (hasComma && hasDot && value.indexOf('.') < value.indexOf(',')) ||
      (!hasDot && !hasComma)
    ) {
      valueFormat = value.replace(/[^-?0-9.]/g, ''); //清除“数字”和“.”以外的字符
      valueFormat = valueFormat.replace(/^\./g, ''); //验证第一个字符是数字而不是.
    }
  } else {
    valueFormat = value.replace(/[^-?0-9.]/g, ''); //清除“数字”和“.”以外的字符
    valueFormat = valueFormat.replace(/^\./g, ''); //验证第一个字符是数字而不是.
  }
  if (isCommaCountry) {
    /* 如果是类似于德国的国家，可以输入',' 、'.'。但是两个不能同时存在*/
    if ((hasComma && !hasDot) || (hasComma && hasDot && value.indexOf(',') < value.indexOf('.'))) {
      valueFormat = valueFormat.replace(/\,{2,}/g, ','); //只保留第一个, 清除多余的
    } else if (
      (hasDot && !hasComma) ||
      (hasComma && hasDot && value.indexOf('.') < value.indexOf(',')) ||
      (!hasDot && !hasComma)
    ) {
      valueFormat = valueFormat.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
    }
  } else {
    valueFormat = valueFormat.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
  }
  formName.setFieldValue(key, valueFormat);
}

/**
 * @Author: YuKi
 * @description: 失去焦点自动补位小数点(仅仅处理大于0并且带小数)、如果存在最大值最小值，需要处理
 * @param {*} formName 处理的表单名字
 * @param {*} key 处理的表单的key
 * @param {any} min 最小值
 * @param {any} max 最大值
 * @param {any} length 长度
 * @return {*}
 */
export function inputDecimalsSize(formName, key, min = NaN, max = NaN, length = NaN) {
  let value = formName.getFieldValue(key);
  if (value == null || value === '' || value === undefined) {
    return;
  }
  if (min !== '' && Number(value) < min) {
    value = min;
  }
  if (max !== '' && Number(value) > max) {
    value = max;
  }
  if (typeof value == 'number') {
    value = value.toString();
  }
  if (length) {
    let array1 = value.split('.');
    let array2 = value.split(',');
    if (!(array1.length > 2 || array2.length > 2)) {
      let hasComma = value.indexOf(',') > -1 ? true : false;
      let hasDot = value.indexOf('.') > -1 ? true : false;
      if (hasDot) {
        let str = value.split('.');
        let currentlen = length - str[1].length;
        if (currentlen < 0) {
          value = array1[0] + '.' + array1[1].slice(0, length);
        } else if (currentlen > 0) {
          for (let index = 0; index < currentlen; index++) {
            value = value + '0';
          }
        }
      } else if (hasComma) {
        let str = value.split(',');
        let currentlen = length - str[1].length;
        if (currentlen < 0) {
          value = array2[0] + ',' + array2[1].slice(0, length);
        } else if (currentlen > 0) {
          for (let index = 0; index < currentlen; index++) {
            value = value + '0';
          }
        }
      }
    }
  }
  formName.setFieldValue(key, value);
}

/**
 * @Author: YuKi
 * @description: input框改造回显：如果是德国需要把.改成，
 * @param {*} number
 * @return {*}
 */
export function dealDots(number, country) {
  if (!(number === null || number === undefined || number === '')) {
    if (nation.includes(country)) {
      return (number = number.toString().replace('.', ','));
    } else {
      return (number = number);
    }
  } else {
    return (number = number);
  }
}

/**
 * @Author: YuKi
 * @description: input框改造回显：如果是德国需要把，改成。
 * @param {*} number
 * @return {*}
 */
export function dealComma(number, country) {
  if (!(number === null || number === undefined || number === '')) {
    if (nation.includes(country)) {
      return (number = number.toString().replace(',', '.'));
    } else {
      return (number = number);
    }
  } else {
    return (number = number);
  }
}
