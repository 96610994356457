import http from '@/utils/http';

/**
 * @Author: Stephen
 * @description: 获取敏感词分页数据
 * @param {any} params
 */
export const getSensitiveWord = (data: any): Promise<object> => {
  return http.put({ url: '/ams/sensitive/select', data });
};

/**
 * @Author: Stephen
 * @description: 新增敏感词
 * @param {any} data
 */
export const addSensitiveWord = (data: any): Promise<null> => {
  return http.post({ url: '/ams/sensitive/insert', data });
};

/**
 * @Author: Stephen
 * @description: 编辑敏感词
 * @param {any} data
 */
export const editSensitiveWord = (data: any): Promise<null> => {
  return http.put({ url: '/ams/sensitive/update', data });
};

/**
 * @Author: Stephen
 * @description: 删除敏感词
 * @param {number} id
 */
export const delSensitiveWord = (id: number): Promise<null> => {
  return http.put({ url: '/ams/sensitive/del', data: { id } });
};
