import http from '@/utils/http';

import { listSearch, listUpdate } from '@/models/maintenanceManagement/responsiblePartyMaintenance';

/**
 * @Author: YuKi
 * @description: 分页获取责任归属方
 * @param {listSearch} params 接口传参
 * @return {*}
 */
export const getResponsiblePartyPage = (params: listSearch): Promise<Object> =>
  http.get({ url: '/ams/archiveMaintenance/getResponsiblePartyPage', params });

/**
 * @Author: YuKi
 * @description:新增编辑责任归属方
 * @param {listUpdate} data  接口传参
 */
export const editResponsibleParty = (data: listUpdate): Promise<Object> =>
  http.post({ url: '/ams/archiveMaintenance/editResponsibleParty', data });

/**
 * @Author: YuKi
 * @description: 删除责任归属方
 * @param {string} id  接口传参
 */
export const delResponsibleParty = (id: string): Promise<string> =>
  http.delete({ url: `/ams/archiveMaintenance/delResponsibleParty?id=${id}` });
