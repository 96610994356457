/*
 * @Description: 用户菜单权限相关接口
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/04/25 09:10:33
 * @LastEditTime: 2023/04/27 17:19:34
 */
import http from '@/utils/http';

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取已关联菜单的用户分页数据
 * @param {any} params
 * @return {*}
 */
export const getMenuRelateUserList = (params: any): Promise<Object> => {
  return http.get({
    url: '/web/userMenuManage/getMenuRelateUserList',
    params,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取未关联菜单的用户分页数据
 * @param {any} params
 * @return {*}
 */
export const getMenuNotRelateUserList = (params: any): Promise<Object> => {
  return http.get({
    url: '/web/userMenuManage/getMenuNotRelateUserList',
    params,
  });
};

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 删除已关联菜单的用户
 * @param {any} data
 * @return {*}
 */
export const deleteSysUser = (data: any): Promise<Object> => {
  return http.delete({
    url: '/web/userMenuManage/deleteSysUser',
    data,
  });
};
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取用户的菜单设置
 * @param {any} params
 * @return {*}
 */
export const getUserMenuTreeData = (params: any): Promise<Object> => {
  return http.get({
    url: '/web/userMenuManage/getUserMenuTreeData',
    params,
  });
};
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 新增或编辑用户菜单设置
 * @param {any} data
 * @return {*}
 */
export const setUserMenuPermission = (data: any): Promise<Object> => {
  return http.post({
    url: '/web/userMenuManage/setUserMenuPermission',
    data,
  });
};
