/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_authorityManagement from './en-US/translation.json';
import zh_authorityManagement from './zh-CN/translation.json';
import ja_authorityManagement from './ja-JP/translation.json';
import de_authorityManagement from './de-DE/translation.json';
import nl_authorityManagement from './nl-NL/translation.json';
import it_authorityManagement from './it-IT/translation.json';
import sp_authorityManagement from './sp-SP/translation.json';
import el_authorityManagement from './el-GR/translation.json';
import fr_authorityManagement from './fr-FR/translation.json';

export const authorityManagement = {
  en_authorityManagement,
  zh_authorityManagement,
  ja_authorityManagement,
  de_authorityManagement,
  nl_authorityManagement,
  it_authorityManagement,
  sp_authorityManagement,
  el_authorityManagement,
  fr_authorityManagement,
};
