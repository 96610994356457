/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Please set LastEditors
 * @Date: 2023/09/06 17:12:26
 * @LastEditTime: 2024/11/09
 */

import http from '@/utils/http';
import { fileDowList, sysDictDetList, safetyDetailList } from '@/models/common';
import { IResponse } from '@/utils/request.type';

export const getProjectList = (): Promise<IResponse<any>> => http.get({ url: '/cloud-rbac/client/portal_as_me' });

/**
 * @Author: YuKi
 * @description: 文件上传
 * @return {*}
 */
export const fileUpload = (data: any): Promise<Object> =>
  http.post({ url: '/stable/fileOperation/fileUploadForResource ', data });

/**
 * @Author: YuKi
 * @description: ARMS文件上传
 * @return {*}
 */
export const fileUploadARMS = (data: any): Promise<Object> => http.post({ url: '/ams/fileOperation/fileUpload', data });

/**
 * @Author: YuKi
 * @description: 查询国家列表
 * @return {*}
 */
export const getCountryList = (): Promise<Object> => http.get({ url: '/stable/user/getCountry' });

/**
 * @Author: YuKi
 * @description: 下载接口，传入文件全路径
 * @param {fileDowList} data  接口传参
 * @return {*}
 */

export const fileDownload = (params: fileDowList): Promise<Object> =>
  http.exportFileTemp({ url: '/ams/fileOperation/fileDownload', params });

/**
 * @description:
 * @param {fileDowList} params
 * @return {*}
 * @LastEditors: XuAnjie
 */
export const fileDownloadBase = (params: fileDowList): Promise<Object> =>
  http.exportFileTemp({ url: '/base/fileOperation/fileDownload', params });

/**
 * @Author: YuKi
 * @description: 查询语言列表
 * @return {*}
 */
export const getLanguageTypeList = (): Promise<Object> => http.get({ url: '/stable/user/getLanguageType' });

/*
 * @Author: YuKi
 * @description: 根据Sn获取天气
 * @param {sysDictDetList} params 接口传参
 */
export const getWeatherData = (params): Promise<object> => {
  return http.get({
    url: '/report/ess/getWeatherData',
    params,
  });
};

export const getRoleList = (): Promise<Object> => http.get({ url: '/web/userManagement/getSysRoleAllList' });

export const getAllSysRoleList = (): Promise<Object> =>
  http.get({ url: '/web/informationCenterManage/getAllSysRoleList' });

/**
 * @description: 获取应用程序类别
 * @param {any} params
 * @LastEditors: XuAnjie
 */
export const getSysProgramType = (params: any): Promise<object> => {
  return http.get({
    url: '/web/SnManage/getSysProgramType',
    params,
  });
};
