/*
 * @Description:权限菜单->菜单翻译管理
 * @Author: YuKi
 * @Date: 2023/02/07
 * @LastEditors: YuKi
 * @LastEditTime: 2023/04/25
 */
import http from '@/utils/http';

import { listSearch, listdetail } from '@/models/menuPermissions/menuTranslation';

/**
 * @Author: YuKi
 * @description:菜单翻译管理->获取右侧菜单翻译列表
 * @param {listSearch} params 接口传参
 * @return {*}
 */
export const getMenuTranslate = (params: listSearch): Promise<Object> =>
  http.get({ url: '/web/menuTranslateManage/getMenuTranslate', params });

/**
 * @Author: YuKi
 * @description:菜单翻译管理->菜单翻译获取编辑数据
 * @param {listdetail} params  接口传参
 * @return {*}
 */
export const getMenuTranslateEdit = (params: listdetail): Promise<Object> =>
  http.get({ url: '/web/menuTranslateManage/getMenuTranslateEdit', params });

/**
 * @Author: YuKi
 * @description:菜单翻译管理->编辑保存
 * @param {any} data  接口传参
 * @return {*}
 */
export const editRemoteModel = (data: any): Promise<Object> =>
  http.put({ url: '/web/menuTranslateManage/editMenuTranslate', data });

/**
 * @Author: YuKi
 * @description:菜单翻译管理->刷新菜单权限
 * @return {*}
 */
export const refreshMenuAuthority = (): Promise<Object> =>
  http.get({ url: '/web/menuTranslateManage/refreshMenuAuthority' });
