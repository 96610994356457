/*
 * @Description:权限菜单->菜单管理
 * @Author: YuKi
 * @Date: 2023/02/07
 * @LastEditors: YuKi
 * @LastEditTime: 2023/04/26
 */
import http from '@/utils/http';

import { listSearch, listdetail, listAdd, listUpdate, listDelete } from '@/models/menuPermissions/menuManage';

/**
 * @Author: YuKi
 * @description:菜单管理->获取左侧树形菜单
 * @return {*}
 */
export const getMenuTree = (): Promise<Object> => http.get({ url: '/web/menuManage/getMenuTree' });

/**
 * @Author: YuKi
 * @description:菜单管理->获取右侧菜单管理列表
 * @param {listSearch} params 接口传参
 * @return {*}
 */
export const getMenuAndSubMenus = (params: listSearch): Promise<Object> =>
  http.get({ url: '/web/menuManage/getMenuAndSubMenus', params });

/**
 * @Author: YuKi
 * @description:菜单管理->菜单翻译获取编辑数据
 * @param {listdetail} data  接口传参
 * @return {*}
 */
export const getMenuDetailsById = (params: listdetail): Promise<Object> =>
  http.get({ url: '/web/menuManage/getMenuDetailsById', params });

/**
 * @Author: YuKi
 * @description:菜单管理->新增
 * @param {listAdd} data  接口传参
 * @return {*}
 */
export const addMenu = (data: listAdd): Promise<Object> => http.post({ url: '/web/menuManage/addMenu', data });

/**
 * @Author: YuKi
 * @description:菜单管理->编辑保存
 * @param {listUpdate} data  接口传参
 * @return {*}
 */
export const modifyMenuById = (data: listUpdate): Promise<Object> =>
  http.put({ url: '/web/menuManage/modifyMenuById', data });

/**
 * @Author: YuKi
 * @description:菜单管理->刷新菜单权限
 * @return {*}
 */
export const refreshMenuAuthority = (): Promise<Object> =>
  http.get({ url: '/web/menuTranslateManage/refreshMenuAuthority' });

/**
 * @Author: YuKi
 * @description:菜单管理->删除
 * @param {listDelete} params  接口传参
 * @return {*}
 */
export const deleteMenuById = (params: listDelete): Promise<string> =>
  http.delete({ url: '/web/menuManage/deleteMenuById', params });
