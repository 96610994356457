/*
 * @Description: 报表中心
 * @Author: April
 * @Date: 2023-12-13 16:46:23
 * @LastEditors: Summer.Chen
 * @LastEditTime: 2024-03-15 15:04:58
 */
import http from '@/utils/http';
// 获取电池换机量
export const getMbatReplacement = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getMbatReplacement', data });
// 获取电池换机量
export const getMinvReplacement = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getMinvReplacement', data });
// 获取系统上门量
export const getWorkOrderVolume = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getWorkOrderVolume', data });
// 获取系统客诉量
export const getComplaintsVolume = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getComplaintsVolume', data });
// 获取客诉分析报表
export const getComplaintsAnalysis = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getComplaintsAnalysis', data });
// 获取周归档责任分类
export const getResponsibilityEnd = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getResponsibilityEnd', data });
// 获取技术端客诉分类
export const getTechnicalClassification = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getTechnicalClassification', data });
// 获取质量端客诉分类
export const getQualityClassification = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getQualityClassification', data });
// 获取部门客诉分类
export const getDepartmentClassification = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getDepartmentClassification', data });
// 获取客诉问题部件
export const getProblemComponent = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getProblemComponent', data });
// 获取用户组度量工时报表
export const getAmsComplaintsWorkHourReport = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getAmsComplaintsWorkHourReport', data });
// 导出用户组度量工时报表 and  质量客诉统计报表
export const exportComplaintReportByGroup = (data: any): Promise<Object> =>
  http.exportExelTemp({ url: '/ams/export/exportComplaintReportByGroup', data });
//质量客诉统计报表列表
export const getQualityStatisticsReport = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getQualityStatistics', data });
//质量客诉关闭率报表
export const getCustomerComplaintReport = (data: any): Promise<Object> =>
  http.post({ url: '/ams/reportManagement/getComplaintsCloseRate', data });
//导出客诉关闭率报表
export const exportComplaintsCloseRate = (data: any): Promise<Object> =>
  http.exportExelTemp({ url: '/ams/reportManagement/exportComplaintsCloseRate', data });
