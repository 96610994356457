/*
 * @Description:组件输入限长
 * @Author: YuKi
 * @Date: 2023/02/20
 * @LastEditors: April
 * @LastEditTime: 2023-06-26 20:10:01
 */
let inputValueLimit = {
  maxTextArea512: 512,
  maxTextValueLeng: 256 /* texter最大长度 */,
  maxInputValueLeng: 128 /* Input最大长度 */,
  maxCustomerName: 64 /*客户名称*/,
  minCustomerName: 4 /*客户名称*/,
  maxSn: 64 /* 系统SN */,
  maxTelephone: 20 /* 电话 */,
  minTelephone: 6 /* 电话 */,
  maxPostcode: 16 /* 邮编 */,
  minPostcode: 4 /* 邮编 */,
  maxEmail: 64 /* 邮箱 */,
  minEmail: 4 /* 邮箱 */,
  maxContactName: 64 /* 联系人 */,
  maxAddressDetail: 128 /*详细地址*/,
  maxTitle: 128 /* 标题 */,
  country: 512 /* 国家/地区 */,
  maxExtWarActcode: 64 /* 延保激活码 */,
  maxSnCheckRequired: 64 /* SN校验码 */,
  maxLicense: 64 /* license */,
  maxPassword: 64 /* 密码 */,
  minPassword: 4 /* 密码 */,
  maxInstallerCompanyname: 128 /* 安装商公司 */,
  maxInstallerAddress: 128 /* 安装商地址 */,
  maxInstallerContactPerson: 128 /* 安装商联系人 */,
  maxInstallerRemark: 128 /* 安装商备注 */,
  maxSystemOrChipModel: 32 /* 系统或芯片型号 */,
  maxSnCodingRules: 32 /* sn编码规则 */,
  maxWarrantyCode: 128 /*质保code */,
  maxProductModel: 52 /*产品型号 */,
  maxNewsTitle: 52 /*新闻标题 */,
  maxDataColumnName: 52 /*资料栏目名称 */,
  maxLanguageKey: 52 /*语言键 */,
  maxResellerAccount: 128 /*分销商账号 */,
  maxInstallerAccount: 128 /*安装商账号 */,
  maxBatterySn: 64 /* 电池SN */,
  maxInverterSN: 64 /* 逆变器SN */,
  maxRegisteredUsername: 64 /* 注册用户名 */,
  maxBindpwd: 64 /* 校验码 */,
  maxBatteryModel: 52 /*产品型号 */,
  maxMeterModel: 52 /*产品型号 */,
  minDefaultCT: 1 /* 默认CT倍率值 */,
  maxDefaultCT: 10000 /* 默认CT倍率值 */,
  maxMoneyType: 15 /* 币种 */,
  maxGroupName: 64 /*用户组名称*/,
  maxAuthName: 64 /*用户组->逆变器型号、SN编码规则、国家、区域名称 */,
  maxMinv: 32 /* 逆变器型号 */,
  maxChargePileTypeName: 32 /*充电桩类型名称 */,
  maxRemarks: 32 /*充电桩、能量报表备注 */,
  maxLogCode: 64 /* 流水号 */,
  maxSysMinv: 64 /* 系统型号 */,
  maxSoftInfo: 190 /* 软件版本说明 */,
  maxNoUpgradeReason: 255 /* 禁止升级原因 */,
  maxInstaller: 64 /* 安装工 */,
  maxVersionType: 64 /* 版本类型 */,
  maxDevVersion: 64 /* 终端版本号/硬件版本号 */,
  maxAppVersionNum: 40 /* 软件版本号 */,
  masSharePwd: 20 /* 分享用户注册密码 */,
  masShareConfirmPwd: 20 /* 分享用户注册确认密码 */,
};

export { inputValueLimit };
